@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.0/normalize.min.css);
* {
    box-sizing: border-box;
}
/* archivo-narrow-regular - latin */
@font-face {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 500;
    src: url(/static/media/archivo-narrow-v8-latin-500.3814dcbe.eot); /* IE9 Compat Modes */
    src: local('Archivo Narrow Medium'), local('ArchivoNarrow-Medium'),
         url(/static/media/archivo-narrow-v8-latin-500.3814dcbe.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/archivo-narrow-v8-latin-500.5ad73547.woff2) format('woff2'), 
         url(/static/media/archivo-narrow-v8-latin-500.49a5712e.woff) format('woff'), 
         url(/static/media/archivo-narrow-v8-latin-500.7ee3013a.ttf) format('truetype'), 
         url(/static/media/archivo-narrow-v8-latin-500.18cadc3f.svg#ArchivoNarrow) format('svg'); /* Legacy iOS */
  }

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;    

    color: rgba(48, 51, 59, 1);
    background-color: rgba(48, 51, 59, 1);

    font-family: 'Archivo Narrow', Fallback, sans-serif;

    overflow-y: hidden;
}

#root {
    /* layout adjustments for web app */
  }

iframe {
    display: block;
    border: 0;
    width: 100%;
    height: 100%;
}

.app {
    display: flex;
    width: 100%;
    height: 100%;
}

#api-frame {
    flex: 1 1 auto;
}

.configurator-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.annotations {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
}

.annotations.visible {
    opacity: 1;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(1.2, 1.2);
                transform: scale(1.2, 1.2);
    }

    to {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(1.2, 1.2);
                transform: scale(1.2, 1.2);
    }

    to {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
}

@-webkit-keyframes ring {
    from {
        -webkit-transform: scale(0.5, 0.5);
                transform: scale(0.5, 0.5);
        opacity: 1.0;
    }

    to {
        -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
        opacity: 0;
    }
}

@keyframes ring {
    from {
        -webkit-transform: scale(0.5, 0.5);
                transform: scale(0.5, 0.5);
        opacity: 1.0;
    }

    to {
        -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
        opacity: 0;
    }
}

.annotation {
    position: absolute;
    top: -8px;
    left: -8px;
}

.annotation.\--occluded {
    opacity: 0.3;
}


.hotspot {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation-name: pulse;
            animation-name: pulse;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.hotspot:after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-animation-name: ring;
            animation-name: ring;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-delay: 0.5;
            animation-delay: 0.5;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.configurator {
    width: 100%;

    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    padding: 10px;

    /* color: #fff; */

    opacity: 1;
    pointer-events: none;
    transition: opacity 0.5s;
    z-index: 100;
    /* height: 268px;     */
    position: fixed;
    padding-bottom: 5%;
    bottom: 0;
    right: 0;
}

.configurator.visible {
    opacity: 1;
    pointer-events: auto;
}

.sketchfab-configurator {
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    justify-content: center;    
    z-index: 1;
}

.configurator-header {
    width: 100%;
    height: 235px; 

    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    padding: 10px;

    color: #fff;

    opacity: 1; 
    z-index: 1;      
}

.option {
    margin: 0 44px;
    /* width: 152px; */
}

.option > label {
    display: block;
}

.option--color .option__control {
    display: flex;
    /* flex-wrap: wrap; */
}

.option--select .option__control {
    margin-top: 4px;    
}

.color {
    flex: 0 0 auto;
    display: block;
    /* width: 24px;
    height: 24px;
    margin: 4px; */

    width: 69px;
    height: 28px;

    /* background: linear-gradient(235.18deg, #080909 24.43%, rgba(8, 9, 9, 0) 77.91%), #212123; */
    /* border: 1px solid #6C6C6C; */
    box-sizing: border-box;
    border-radius: 4px;
    margin: 1px;
}

.color__swatch {
    display: block;
    position: relative;
    /* width: 24px;
    height: 24px; */
    cursor: pointer;
    transition: border 0.3s, box-shadow 0.3s, -webkit-transform .2s ease;
    transition: border 0.3s, box-shadow 0.3s, transform .2s ease;
    transition: border 0.3s, box-shadow 0.3s, transform .2s ease, -webkit-transform .2s ease;
    border-radius: 4px;
    
    width: 69px;
    height: 28px;

    /* background: linear-gradient(235.18deg, #080909 24.43%, rgba(8, 9, 9, 0) 77.91%), #212123; */
    border: 1px solid #6C6C6C;
    box-sizing: border-box;  
    margin: 1px;
    z-index: 1;   
}

.color__swatch::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.color input {
    display: none;
}

.color input:checked + .color__swatch {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    z-index: 2; 
}

.color input:checked + .color__swatch::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 4px; 
}

.color__name {
    display: none;
}

.option--select select {
    display: none;
}

.option--select .option__control input[type='radio'] {
    display: none;
}

.option label:not(.toggle-label) {
    /* color: #6c6c6c; */
    color: #ffffff;
    text-shadow: -1px -1px 0 #00000055,
     1px -1px 0 #00000055,
     -1px 1px 0 #00000055,
      1px 1px 0 #00000055;
}

.option--select .option__control label:first-child span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.option--select .option__control label:last-child span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.option--select .option__control span {
    display: inline-block;
    padding: 4px 8px;
    font-size: 12px;
    background: #333;
    cursor: pointer;
}

.option--select .option__control input:checked + span {
    background: #1caad9;
}

.overlay{top:0;left:0;width:90%;height:100%;position:absolute;}

.grow { transition: all .1s ease-in-out; }
.grow:hover { -webkit-transform: scale(1.1); transform: scale(1.1); }
